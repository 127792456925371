// document.addEventListener("DOMContentLoaded", function () {
//     var $lis = $('.bullets-container ul li')

//     $(window).on('resize', function () { 
//         var initialLeft;
//         $lis.each(function(i){
//             if (i === 0) {
//                 initialLeft = $(this).position().left;
//             }
//             $(this).toggleClass('first', i === 0 || $(this).position().left === initialLeft);
//         });
//     }).resize();    
// })
