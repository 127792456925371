import g from '../globals'

if (g.elementInDom('.accordion-content')) {

    const accordionItems = document.querySelectorAll('.accordion-item')

    var isActive = false;

    accordionItems.forEach((obj, index) => {
        obj.querySelector('.accordion-title').addEventListener('click', (e) => {
            e.preventDefault()

            new Promise((resolve, reject) => {
                closeAllItems()
                resolve()
            })
            .then(() => {
                obj.classList.add('toggle-open')
            })
            .catch((error) => {
                console.log(error)
            })
                
        })
    })

    function closeAllItems() {
        accordionItems.forEach((obj, index) => {
            obj.classList.remove('toggle-open')
        })
    }
}