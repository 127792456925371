import g from './globals'

if (g.elementInDom('.matador-jobs')) {
    var btnDefault = document.querySelectorAll('.matador-jobs .btn-default'),
        btn = document.querySelectorAll('.matador-jobs button'),
        jobCategories = document.querySelectorAll('.matador-category-list a'),
        resetButton = document.querySelector('#matador-search-reset')

        btnDefault.forEach((obj, index) => {
            var btnHTML = obj.innerHTML
            var newBtnHTML = '<span>' + btnHTML + "</span>"
            obj.setAttribute('data-text', btnHTML)
            obj.innerHTML = newBtnHTML
        })

        btn.forEach((obj, index) => {

        })

        function wrap (toWrap, wrapper) {
            wrapper = wrapper || document.createElement('div');
            toWrap.parentNode.appendChild(wrapper);
            return wrapper.appendChild(toWrap);
        }


        // Use matador Job category parameters for categories
        jobCategories.forEach((obj, index) => {
            var categoryURL = obj.getAttribute('href'),
                lastSegmentURL = categoryURL.split("/"),
                slug = lastSegmentURL[lastSegmentURL.length-2]

            obj.addEventListener('click', (e) => {
                e.preventDefault()
                removeParam('matador-categories')     
            
                window.location = location.href += "?matador-categories=" + slug;
            })
            
        })

        resetButton.addEventListener('click', (e) => {
            e.preventDefault();
            removeParam('matador-categories')
            location.reload()  
        })

}

if (g.elementInDom('.jobs-single-container')) {
    var jobsSingleHTML = document.querySelector('.jobs-single-container'),
        pTags = jobsSingleHTML.querySelectorAll('p')
        
    jobsSingleHTML.innerHTML = jobsSingleHTML.innerHTML.replace(/&nbsp;/g, '')
}


function removeParam(parameter)
{
  var url=document.location.href;
  var urlparts= url.split('?');

 if (urlparts.length>=2)
 {
  var urlBase=urlparts.shift(); 
  var queryString=urlparts.join("?"); 

  var prefix = encodeURIComponent(parameter)+'=';
  var pars = queryString.split(/[&;]/g);
  for (var i= pars.length; i-->0;)               
      if (pars[i].lastIndexOf(prefix, 0)!==-1)   
          pars.splice(i, 1);
  url = urlBase+'?'+pars.join('&');
  window.history.pushState('',document.title,url); // added this line to push the new url directly to url bar .

}
return url;
}