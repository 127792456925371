import g from '../globals'

if (g.elementInDom('.service-packages')) {

    var packages = document.querySelectorAll('.package'),
        packages_content = document.querySelectorAll('.package-content'),
        nextButtons = document.querySelectorAll('.next'),
        packageContentContainer = document.querySelector('.packages-content')

    setButtons()
    setActive(packages[0], packages_content[0], true)

    packages.forEach((obj, index) => {
        obj.addEventListener('click', (e) => {
            e.preventDefault()

            var packageIndex = obj.dataset.index,
                packageContent = document.querySelector('.package-content-' + (index + 1))

            if (obj.classList.contains('package-active')) {
                packageContentContainer.classList.toggle('hide-packages')
                obj.querySelector('.btn-alt').classList.toggle('remove-less')
            } else {
                packageContentContainer.classList.remove('hide-packages')
                obj.querySelector('.btn-alt').classList.remove('remove-less')
                setActive(obj, packageContent)
            }
        })
    })

    function setButtons () {
        nextButtons.forEach((obj, index) => {
            obj.querySelector('.btn-alt').addEventListener('click', (event) => {
                event.preventDefault()
                setActive(document.querySelector('.package-' + (index + 2)), document.querySelector('.package-content-' + (index + 2)))
                packageContentContainer.scrollIntoView({behavior: 'smooth', block: 'start'})
            })
        })
    }

    function setActive(packageActive, packageActiveContent, isInit = false) {

        return new Promise((resolve, reject) => {
            packages.forEach((obj, index) => {
                obj.classList.remove('package-active')
            })
            packages_content.forEach((obj, index) => {
                obj.classList.remove('package-active')
                obj.classList.remove('fadeIn')
            })
            setTimeout(() => {
                resolve()
            }, 0)
        })
        .then(() => {
            packageActive.classList.add('package-active')
            packageActiveContent.classList.add('package-active')
            packageActive.querySelector('.btn-alt').classList.remove('remove-less')

            setTimeout(() => {
                packageActiveContent.classList.add('fadeIn')
            }, 250)
        })
        .catch((error) => {
            console.log(error)
        })
    }
}