    import g from '../globals'

    if (g.elementInDom('.process-steps')) {
    
        var steps = document.querySelectorAll('.step'),
            steps_content = document.querySelectorAll('.step-content'),
            nextButtons = document.querySelectorAll('.next'),
            stepContentContainer = document.querySelector('.steps-content')

        setButtons()
        setActive(steps[0], steps_content[0], true)
        
        steps.forEach((obj, index) => {
            obj.addEventListener('click', (e) => {
                e.preventDefault()

                var stepIndex = obj.dataset.index,
                    stepContent = document.querySelector('.step-content-' + (index + 1))

                if (obj.classList.contains('step-active')) {
                    stepContentContainer.classList.toggle('hide-steps')
                    obj.querySelector('.btn-alt').classList.toggle('remove-less')
                } else {
                    stepContentContainer.classList.remove('hide-steps')
                    obj.querySelector('.btn-alt').classList.remove('remove-less')
                    setActive(obj, stepContent)
                }
            })
        })

        function setButtons () {
            nextButtons.forEach((obj, index) => {
                obj.querySelector('.btn-alt').addEventListener('click', (event) => {
                    event.preventDefault()
                    setActive(document.querySelector('.step-' + (index + 2)), document.querySelector('.step-content-' + (index + 2)))
                    stepContentContainer.scrollIntoView({behavior: 'smooth', block: 'start'})
                })
            })
        }
        
        function setActive(stepActive, stepActiveContent, isInit = false) {

            return new Promise((resolve, reject) => {
                steps.forEach((obj, index) => {
                    obj.classList.remove('step-active')
                })
                steps_content.forEach((obj, index) => {
                    obj.classList.remove('step-active')
                    obj.classList.remove('fadeIn')
                })
                setTimeout(() => {
                    resolve()
                }, 0)
            })
            .then(() => {
                stepActive.classList.add('step-active')
                stepActiveContent.classList.add('step-active')
                stepActive.querySelector('.btn-alt').classList.remove('remove-less')

                setTimeout(() => {
                    stepActiveContent.classList.add('fadeIn')
                }, 250)
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }
